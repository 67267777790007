import React from "react";
import styled from "styled-components";

import { Wrapper as _Wrapper, Container, Accent } from "../../global";
import { Typography } from "@material-ui/core";
import kerryProfile from "../../../images/about/kerryProfile.png";
import kerryAward from "../../../images/about/kerryAward.png";

const Features: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <Grid>
          <MediaContainer>
            <img src={kerryProfile} />
            <SecondImage src={kerryAward} />
          </MediaContainer>
          <TextContainer>
            <Typography variant="h2">
              <b>DR KERRY SPACKMAN</b>
            </Typography>
            <Typography variant="h4">
              <Accent>
                <b>CEO AND FOUNDER</b>
              </Accent>
            </Typography>
            <Typography variant="inherit">
              Dr Kerry Spackman, our CEO and co-founder, was inspired to develop Ako Maps from his experiences as a
              maths teacher and from studying maths at the University of Auckland. Kerry won the Senior Prize in Applied
              Mathematics, and years later this study technique was modernised as Ako Maps.
            </Typography>
            <Typography variant="inherit">Winner of the KEA New Zealand World Class Award 2010</Typography>
            <Typography variant="inherit">
              Winner of the NEEDA Award for most significant Electronic Export from NZ
            </Typography>
            <Typography variant="inherit">
              Winner of the Senior Prize in Applied Mathematics Auckland University
            </Typography>
            <Typography variant="inherit">Ph.D.Cognitive Neuroscience – Psychophysics</Typography>
            <Typography variant="inherit">
              Consultant to 4 Formula One Teams over 7 years: developed aerodynamic modelling, simulation, mathematical
              optimisation, training F- 1 drivers and developing simulators
            </Typography>
            <Typography variant="inherit">Author of No.1 bestselling book, The Winner&apos;s Bible</Typography>
            <Typography variant="inherit">Author of No.1 bestselling book, The Ant and the Ferrari</Typography>
            <Typography variant="inherit">
              Founder and Director of the NZ Government&apos;s GoldMine Project, developing electronics and mathematical
              analysis for NZ&apos;s Olympic Medallists
            </Typography>
            <Typography variant="inherit">
              Wrote and is Co-Producer for the upcoming major international movie on Einstein, Einstein&apos;s Compass
            </Typography>
            <Typography variant="inherit">
              Founded and Developed the Vehicle Dynamics Engineer course for Ford Motor Co. World Headquarters along
              with partner 3-time F- 1 Champion, Sir Jackie Stewart
            </Typography>
            <Typography variant="inherit">Adjunct Professor of Empirical Metaphysics</Typography>
            <Typography variant="inherit">
              Wrote and Presented the Discovery TV feature, Speed Science which has been translated and shown around the
              world to over 300 million viewers
            </Typography>
          </TextContainer>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Features;

const Wrapper = styled(_Wrapper)`
  background-color: black;
`;
const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
`;

const TextContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: center;
  padding: 32px 16px 32px;
  line-height: 28px;
  letter-spacing: 2px;
  > span {
    padding-top: 32px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
  }
`;

const MediaContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 40%;
  justify-content: center;
  place-items: end;
  padding: 32px 16px 32px;
  > img {
    padding: 64px 0;
    width: 300px;
  }
  > span {
    padding-top: 32px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    place-items: center;
  }
`;

const SecondImage = styled.img`
  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;
