import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { Accent } from "../../global";
import headerImage from "../../../images/about/KerryCar5.jpg";

const About: React.FC = () => {
  return (
    <div id="header">
      <Container>
        <BackgroundContainer>
          <BackgroundImage src={headerImage} />
        </BackgroundContainer>
        <Gradient />
        <Copy>
          <Typography variant="h1">
            <b>
              <Accent>DR KERRY SPACKMAN</Accent>
            </b>
          </Typography>
          <Typography variant="h1" align="right">
            <b>
              <Accent> & GRANT DAVIDSON </Accent>
            </b>
          </Typography>
          <Typography variant="h3">THE MINDS BEHIND CPLABS</Typography>
        </Copy>
      </Container>
    </div>
  );
};

export default About;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  background-color: black;
`;
const Copy = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 1100px;
  margin-left: 128px;
  margin-right: 64px;
  color: white;
  height: 80vh;
  position: relative;
  justify-content: center;
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 16px;
    margin-right: 16px;
    padding: 16px;
  }
  @media (max-height: ${props => props.theme.screen.sm}) {
    height: 100vh;
  }
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 1), 65%, rgba(0, 0, 0, 0));
`;
const BackgroundContainer = styled.div`
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
`;
const BackgroundImage = styled.img`
  width: 100%;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: auto;
    height: auto;
  }
`;
