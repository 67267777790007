import React from "react";
import styled from "styled-components";

import { Wrapper as _Wrapper, Container, Accent } from "../../global";
import { Typography } from "@material-ui/core";

import grantProfile from "../../../images/about/grantProfile.png";

const Features: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <Grid>
          <MediaContainer>
            <img src={grantProfile} />
          </MediaContainer>
          <TextContainer>
            <Typography variant="h2">
              <b>GRANT DAVISON</b>
            </Typography>
            <Typography variant="h4">
              <Accent>
                <b>DIRECTOR OF SOFTWARE DEVELOPMENT</b>
              </Accent>
            </Typography>
            <Typography variant="inherit">
              Grant’s role at CPL includes developing and maintaining the software powering Ako Maps.
            </Typography>
            <Typography variant="inherit">
              Prior to CPL, Grant lead the development of performance analysis systems for New Zealand&apos;s Olympic
              program.
            </Typography>
            <Typography variant="inherit">
              Grant has also worked with Kerry on a wide range of projects, including F1 track side performance
              optimisation software for Jaguar Racing.
            </Typography>
            <Typography variant="inherit">
              Grant has a Masters of Engineering from the University of Auckland
            </Typography>
          </TextContainer>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Features;

const Wrapper = styled(_Wrapper)`
  background-color: black;
`;
const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TextContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: center;
  padding: 32px 16px 32px;
  line-height: 28px;
  letter-spacing: 2px;
  > span {
    padding-top: 32px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
  }
`;

const MediaContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 40%;
  justify-content: center;
  place-items: end;
  padding: 32px 16px 32px;
  > img {
    padding: 64px 0;
    width: 300px;
  }
  > span {
    padding-top: 32px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    place-items: center;
  }
`;
